<template>
	<div class="register">
		<simple-header title="新用户注册"></simple-header>
		<div class="page-bg pt-5">
			<div class="grid">
				<div class="warp">
					<div class="signup">
						<div class="signup-header d-flex align-items-center">
							<div class="row w-100 m-0">
								<div class="col w-50">
									<a href="javascript:;" :class="{'text-theme' : current == 0}">
										<h6 @click="onSwitch(0)" class="text-center mb-0">个人注册</h6>
									</a>
								</div>
								<div class="col w-50">
									<a href="javascript:;" :class="{'text-theme' : current == 1}">
										<h6 @click="onSwitch(1)" class="text-center mb-0">企业注册</h6>
									</a>
								</div>
							</div>
						</div>
						<div class='signup-details'>
							<div v-if="tips != ''" class="ml-4 mr-4">
								<div class="alert alert-danger mt-2 mb-2 p-1">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
										class="bi bi-lightbulb" viewBox="0 0 16 16">
										<path
											d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
									</svg>
									<span class="ml-1">{{tips}}</span>
								</div>
							</div>
							<form class='signup-details_form'>
								<div v-if="current == 1">
									<div class='signup-details_form__item' :class="{'mt-0' : tips != ''}">
										<b-form-input v-model="companyName" class="w-100" placeholder="请输入企业名称"
											maxlength='50' autocomplete='off'></b-form-input>
									</div>
									
									<div class='signup-details_form__item'>
										<b-form-input v-model="legalName" class="w-100" placeholder="请输入法人姓名"
											maxlength='20' autocomplete='off'></b-form-input>
									</div>
								</div>
								
								<div class='signup-details_form__item' :class="{'mt-0' : tips != '' && current == 0}">
									<label class='icon icon-phone'></label>
									<b-form-input v-model="mobile" class="border-left-0 rounded-0" placeholder="请输入常用的手机号"
										maxlength='11' autocomplete='off'></b-form-input>
								</div>

								<div class='signup-details_form__item'>
									<label class='icon icon-captcha'></label>
									<b-form-input class="border-left-0 rounded-0" v-model="code" placeholder='动态码'
										maxlength='6' autocomplete='off' style="width:148px;"></b-form-input>
									<b-button class="ml-2" @click="getCode"
										:variant="seconds == 0 ? 'success' : 'secondary'" size="sm"
										:disabled="seconds > 0">{{seconds == 0 ? '获取动态码' : seconds + 'S后重试'}}
									</b-button>
								</div>

								<div class='signup-details_form__item'>
									<label class='icon icon-password'></label>
									<b-form-input v-model="password" class="border-left-0 rounded-0" maxlength='16'
										placeholder='密码（8-16位字母/数字/符号）' :type="isEyeOpen ? 'text' : 'password'" autocomplete='off'></b-form-input>
									<em @click="onEyeToggle" class='eye-icon' :class="[isEyeOpen ? 'eye-close' : 'eye-open']" title='显示密码'></em>
								</div>
								<div class='signup-details_form__item'>
									<div class="d-flex flex-row">
										<div class="d-flex text-sm">
											<b-form-checkbox v-model="isAgree"
												value="true" unchecked-value="false">
												我已阅读并同意
											</b-form-checkbox>
										</div>
										<div class="d-flex ml-1">
											<strong class="text-sm-left">
												<a @click="isServiceShow = true" href='javascript:;'>服务协议</a>、
												<a @click="isLawShow = true" href='javascript:;'>隐私保护政策</a>
											</strong>
										</div>
									</div>
								</div>
								<div class='signup-details_form__item'>
									<b-button @click="register()" class="w-100" variant="success">注册</b-button>
								</div>
								<div class="login-other-ways-login register">
									<div class="authorize-login register">
										<p class="clearfix authorize-login-title mt-1 mb-1">
											<span class="authorize-login-title-left">
												使用第三方账号登录
											</span>
										</p>
										<ul class="clearfix">
											<li class="authorize-login-item">
												<a class="j-authorize-login-item qq-login oauth-param" title="QQ登录"></a>
											</li>
											<li class="authorize-login-item">
												<a class="j-authorize-login-item wechat-login oauth-param"
													title="微信登录"></a>
											</li>
											<li class="authorize-login-item">
												<a class="j-authorize-login-item sina-login oauth-param"
													title="微博登录"></a>
											</li>
										</ul>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<icp-footer></icp-footer>
		<b-modal title="平台服务协议" dialog-class="width-1200" v-model="isServiceShow"  footer-class="justify-content-center" hide-header-close  ok-title="我知道了" ok-variant="success" modal-cancel="true">
			<div class="text-gray-5 line-h-28 f-14">
                    <p>欢迎您与各农村综合产权交易服务平台经营者（详见定义条款）共同签署本《农村综合产权交易服务平台服务协议》（下称“本协议”）并使用农村综合产权交易服务平台服务！</p>
                    <p>本协议为《农村综合产权交易服务协议》修订版本，自本协议发布之日起，农村综合产权交易服务平台各处所称“农村综合产权交易服务协议”均指本协议。</p>
                    <p>各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。</p>
                    <p><strong>【审慎阅读】</strong>您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。<strong>
                        <u>请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。</u></strong>如您对协议有任何疑问，可向农村综合产权交易服务平台客服咨询。
                    </p>
                    <p><strong>【签约动作】</strong>当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与农村综合产权交易服务平台达成一致，成为农村综合产权交易服务平台“用户”。<strong>
                        <u>阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</u></strong></p>
                    <p></p>
                    <p class="margin-t-30 text-danger font-weight font-18">一、 定义</p>
                    <p>农村综合产权交易服务<strong>平台</strong>：指包括农村综合产权交易服务平台（域名为 51cqjy.com）等网站及客户端。</p>
                    <p>农村综合产权交易服务平台：农村综合产权交易服务平台经营者的单称或合称，包括江西润宇信息科技有限公司。</p>
                    <p><strong>农村综合产权交易平台服务</strong>：农村综合产权交易服务平台基于互联网，以包含农村综合产权交易服务平台网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。</p>
                    <p><strong>农村综合产权交易平台规则</strong>：包括在所有农村综合产权交易服务平台规则频道内已经发布及后续发布的全部规则、解读、公告等内容以及各平台在帮派、论坛、帮助中心内发布的各类规则、实施细则、产品流程说明、公告等。
                    </p>
                    <p><strong>同一用户</strong>：使用同一身份认证信息或经农村综合产权交易服务平台排查认定多个农村综合产权交易服务平台账户的实际控制人为同一人的，均视为同一用户。</p>
                    <p></p>
                    <p class="margin-t-30 text-danger font-weight font-18">二、 协议范围</p>
                    <p><span class="font-warning"><strong>2.1</strong><strong>签约主体</strong></span></p>
                    <p><strong>【平等主体】</strong>本协议由您与农村综合产权交易服务平台经营者共同缔结，本协议对您与农村综合产权交易服务平台经营者均具有合同效力。</p>
                    <p>
                        <strong>【主体信息】</strong>农村综合产权交易服务平台经营者是指经营农村综合产权交易服务平台的各法律主体，您可随时查看农村综合产权交易服务平台各网站首页底部公示的证照信息以确定与您履约的农村综合产权交易服务平台主体。本协议项下，<strong>
                        <u>农村综合产权交易服务平台经营者可能根据农村综合产权交易服务平台的业务调整而发生变更，变更后的农村综合产权交易服务平台经营者与您共同履行本协议并向您提供服务，农村综合产权交易服务平台经营者的变更不会影响您本协议项下的权益。农村综合产权交易服务平台经营者还有可能因为提供新的农村综合产权交易服务平台服务而新增，如您使用新增的农村综合产权交易服务平台服务的，视为您同意新增的农村综合产权交易服务平台经营者与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。</u></strong>
                    </p>
                    <p><span class="font-warning"><strong>2.2</strong><strong>补充协议</strong></span></p>
                    <p>由于互联网高速发展，您与农村综合产权交易服务平台签署的本协议列明的条款并不能完整罗列并覆盖您与农村综合产权交易服务平台所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，农村综合产权交易服务平台<strong>
                        <u>法律声明及隐私权政策</u></strong><strong>
                        <u>、农村综合产权交易服务平台规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用农村综合产权交易服务平台服务，视为您同意上述补充协议。</u></strong><br></p>
                    <p class="margin-t-30 text-danger font-18 font-weight">三、账户注册与使用</p>
                    <p><span class="font-warning"><strong>3.1</strong><strong>用户资格</strong></span></p>
                    <p>您确认，在您开始注册程序使用农村综合产权交易服务平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。<strong>
                        <u>若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</u></strong></p>
                    <p><span class="font-warning"><strong>3.2</strong><strong>账户说明</strong></span></p>
                    <p><strong>【账户获得】</strong>当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得农村综合产权交易服务平台账户并成为农村综合产权交易服务平台用户。</p>
                    <p><strong>【账户使用】</strong>您有权使用您设置或确认的农村综合产权交易服务平台会员名、邮箱、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）登录农村综合产权交易服务平台。</p>
                    <p>您有权使用您的农村综合产权交易服务平台账户登录农村综合产权交易服务平台而无需另行设置账户，但您的农村综合产权交易服务平台账户于2014年1月1日前注册或者您注册农村综合产权交易服务平台账户时所使用的邮箱或手机号码已在农村综合产权交易服务平台注册或使用过的除外。</p>
                    <p><span class="font-warning"><strong>3.3</strong><strong>注册信息管理</strong></span></p>
                    <p><strong>3.3.1</strong><strong>真实合法</strong></p>
                    <p><strong>【信息真实】</strong>在使用农村综合产权交易服务平台服务时，您应当按农村综合产权交易服务平台页面的提示准确完整地提供您的信息（包括您的姓名及电子邮件地址、联系电话、联系地址等），以便农村综合产权交易服务平台或其他用户与您联系。<strong>
                        <u>您了解并同意，您有义务保持您提供信息的真实性及有效性。</u></strong></p>
                    <p><strong>【会员名的合法性】
                        <u>您设置的农村综合产权交易服务平台会员名不得违反国家法律法规及农村综合产权交易服务平台规则关于会员名的管理规定，否则农村综合产权交易服务平台可回收您的农村综合产权交易服务平台会员名。</u></strong>农村综合产权交易服务平台会员名的回收不影响您以邮箱、手机号码登录农村综合产权交易服务平台并使用农村综合产权交易服务平台服务。
                    </p>
                    <p><strong>3.3.2</strong><strong>更新维护</strong></p>
                    <p>
                        您应当及时更新您提供的信息，在法律有明确规定要求农村综合产权交易服务平台作为平台服务提供者必须对部分用户（如平台卖家等）的信息进行核实的情况下，农村综合产权交易服务平台将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整的信息。</p>
                    <p><strong>
                        <u>如农村综合产权交易服务平台按您最后一次提供的信息与您联系未果、您未按农村综合产权交易服务平台的要求及时提供信息、您提供的信息存在明显不实的，您将承担因此对您自身、他人及农村综合产权交易服务平台造成的全部损失与不利后果。</u></strong>
                    </p>
                    <p><span class="font-warning"><strong>3.4</strong><strong>账户安全规范</strong></span></p>
                    <p><strong>【账户安全保管义务】</strong>您的账户为您自行设置并由您保管，农村综合产权交易服务平台任何时候均不会主动要求您提供您的账户。因此，建议您务必保管好您的账户，
                        并确保您在每个上网时段结束时退出登录并以正确步骤离开农村综合产权交易服务平台。</p>
                    <p><strong>
                        <u>账户因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。</u></strong></p>
                    <p><strong>【账户行为责任自负】</strong>除农村综合产权交易服务平台存在过错外，<strong>
                        <u>您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。</u></strong></p>
                    <p>
                        <strong>【日常维护须知】</strong>如发现任何未经授权使用您账户登录农村综合产权交易服务平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知农村综合产权交易服务平台，并授权农村综合产权交易服务平台将该信息同步给农村综合产权交易服务平台。<strong>
                        <u>您理解农村综合产权交易服务平台对您的任何请求采取行动均需要合理时间，除农村综合产权交易服务平台存在过错外，农村综合产权交易服务平台对在采取行动前已经产生的后果不承担任何责任。</u></strong></p>
                    <p></p>
                    <p class="margin-t-30 text-danger font-18 font-weight">四、农村综合产权交易服务平台服务及规范</p>
                    <p><strong>【服务概况】</strong>您有权在农村综合产权交易服务平台上享受店铺管理、商品及/或服务的销售与推广、商品及/或服务的购买与评价、交易争议处理等服务。农村综合产权交易服务平台提供的服务内容众多，具体您可登录农村综合产权交易服务平台浏览。
                    </p>
                    <p><span class="font-warning"><strong>4.1</strong><strong>店铺管理</strong></span></p>
                    <p><strong>【店铺创建】</strong>通过在农村综合产权交易服务平台创建店铺，您可发布全新或二手商品及/或服务信息并与其他用户达成交易。</p>
                    <p><strong>
                        <u>基于农村综合产权交易服务平台管理需要，您理解并认可，同一用户在农村综合产权交易服务平台仅能开设一家店铺，农村综合产权交易服务平台可关闭您在农村综合产权交易服务平台同时开设的其他店铺。</u></strong></p>
                    <p><strong>【店铺转让】</strong>由于店铺转让实质为店铺经营者账户的转让，店铺转让的相关要求与限制请适用本协议3.2条账户转让条款。</p>
                    <p><strong>【店铺关停】</strong>您有权通过使用店铺打烊工具短暂关停您的店铺，<strong>
                        <u>您应当对您店铺关停前已达成的交易继续承担发货、退换货及质保维修、维权投诉处理等交易保障责任。</u></strong></p>
                    <p><strong>
                        <u>在您的店铺连续六周无出售中的商品的情况下，土流也可依据农村综合产权交易服务平台规则关停您的店铺。</u></strong></p>
                    <p>依据上述约定关停店铺均不会影响您已经累积的信用。</p>
                    <p><span class="font-warning"><strong>4.2</strong><strong>商品及</strong><strong>/</strong><strong>或服务的销售与推广</strong></span>
                    </p>
                    <p><strong>【商品及</strong><strong>/</strong><strong>或服务信息发布】</strong>通过农村综合产权交易服务平台提供的服务，您有权通过文字、图片、视频、音频等形式在农村综合产权交易服务平台上发布商品及/或服务信息、招揽和物色交易对象、达成交易。
                    </p>
                    <p><strong>【禁止销售范围】</strong>您应当确保您对您在农村综合产权交易服务平台上发布的商品及/或服务享有相应的权利，<strong>
                        <u>您不得在农村综合产权交易服务平台上销售以下商品及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或提供以下服务：</u></strong></p>
                    <p><strong>
                        <u>（一）国家禁止或限制的；</u></strong></p>
                    <p><strong>
                        <u>（二）侵犯他人知识产权或其它合法权益的；</u></strong></p>
                    <p><strong>
                        <u>（三）农村综合产权交易服务平台规则或各平台与您单独签署的协议中已明确说明不适合在农村综合产权交易服务平台上销售及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或提供的。</u></strong></p>
                    <p><strong>【交易秩序保障】</strong>您应当遵守诚实信用原则，确保您所发布的商品及/或服务信息真实、与您实际所销售的商品及/或提供的服务相符，并在交易过程中切实履行您的交易承诺。
                    </p>
                    <p>
                        您应当维护农村综合产权交易服务平台市场良性竞争秩序，不得贬低、诋毁竞争对手，不得干扰农村综合产权交易服务平台上进行的任何交易、活动，不得以任何不正当方式提升或试图提升自身的信用度，不得以任何方式干扰或试图干扰农村综合产权交易服务平台的正常运作。</p>
                    <p><span class="font-warning"><strong>4.3</strong><strong>交易争议处理</strong></span></p>
                    <p><strong>【交易争议处理途径】</strong>您在农村综合产权交易服务平台交易过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：</p>
                    <p>（一）与争议相对方自主协商；</p>
                    <p>（二）使用农村综合产权交易服务平台提供的争议调处服务；</p>
                    <p>（三）请求消费者协会或者其他依法成立的调解组织调解；</p>
                    <p>（四）向有关行政部门投诉；</p>
                    <p>（五）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；</p>
                    <p>（六）向人民法院提起诉讼。</p>
                    <p><strong>【平台调处服务】</strong>如您选择使用农村综合产权交易服务平台的争议调处服务，则表示您认可农村综合产权交易服务平台的客服或大众评审员（“调处方”）作为独立的第三方根据其所了解到的争议事实并依据农村综合产权交易服务平台规则所作出的调处决定（包括调整相关订单的交易状态、判定将争议款项的全部或部分支付给交易一方或双方等）。在农村综合产权交易服务平台调处决定作出前，您可选择其他途径解决争议以中止农村综合产权交易服务平台的争议调处服务。
                    </p>
                    <p><strong>
                        <u>如您对调处决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行调处决定。</u></strong></p>
                    <p><span class="font-warning"><strong>4.4</strong><strong>费用</strong></span></p>
                    <p>
                        农村综合产权交易服务平台为农村综合产权交易服务平台向您提供的服务付出了大量的成本，除农村综合产权交易服务平台明示的收费业务外，农村综合产权交易服务平台向您提供的服务目前是免费的。如未来农村综合产权交易服务平台向您收取合理费用，农村综合产权交易服务平台会采取合理途径并以足够合理的期限提前通过法定程序并以本协议第八条约定的方式通知您，确保您有充分选择的权利。</p>
                    <p><span class="font-warning"><strong>4.5</strong><strong>责任限制</strong></span></p>
                    <p><strong>【不可抗力】</strong>农村综合产权交易服务平台负责"按现状"和"可得到"的状态向您提供农村综合产权交易服务平台服务。农村综合产权交易服务平台依法律规定承担基础保障义务，但<strong>
                        <u>无法对由于信息网络设备维护、连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，暴乱，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或因第三方原因而给您造成的损害结果承担责任。</u></strong>
                    </p>
                    <p><strong>【海量信息】</strong>农村综合产权交易服务平台仅向您提供农村综合产权交易服务平台服务，您了解农村综合产权交易服务平台上的信息系用户自行发布，且可能存在风险和瑕疵。<strong>
                        <u>鉴于农村综合产权交易服务平台具备存在海量信息及信息网络环境下信息与实物相分离的特点，农村综合产权交易服务平台无法逐一审查商品及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或服务的信息，无法逐一审查交易所涉及的商品及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。</u></strong></p>
                    <p><strong>【调处决定】</strong>您理解并同意，在争议调处服务中，农村综合产权交易服务平台的客服、大众评审员并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，<strong>
                        <u>除存在故意外，调处方对争议调处决定免责。</u></strong></p>
                    <p></p>
                    <p class="margin-t-30 font-18 text-danger font-weight">五、用户信息的保护及授权</p>
                    <p><span class="font-warning"><strong>5.1</strong><strong>个人信息的保护</strong></span></p>
                    <p>
                        农村综合产权交易服务平台非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用农村综合产权交易服务平台提供的服务时，您同意农村综合产权交易服务平台按照在农村综合产权交易服务平台上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。农村综合产权交易服务平台希望通过隐私权政策向您清楚地介绍农村综合产权交易服务平台对您个人信息的处理方式，因此农村综合产权交易服务平台建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。</p>
                    <p><span class="font-warning"><strong>5.2</strong><strong>非个人信息的保证与授权</strong></span></p>
                    <p><strong>【信息的发布】</strong>您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，<strong>
                        <u>农村综合产权交易服务平台可对您发布的信息依法或依本协议进行删除或屏蔽。</u></strong></p>
                    <p><strong>【禁止性信息】
                        <u>您应当确保您所发布的信息不包含以下内容：</u></strong></p>
                    <p><strong>
                        <u>（一）违反国家法律法规禁止性规定的；</u></strong></p>
                    <p><strong>
                        <u>（二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</u></strong></p>
                    <p><strong>
                        <u>（三）欺诈、虚假、不准确或存在误导性的；</u></strong></p>
                    <p><strong>
                        <u>（四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</u></strong></p>
                    <p><strong>
                        <u>（五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；</u></strong></p>
                    <p><strong>
                        <u>（六）存在可能破坏、篡改、删除、影响农村综合产权交易服务平台任何系统正常运行或未经授权秘密获取农村综合产权交易服务平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；</u></strong>
                    </p>
                    <p><strong>
                        <u>（七）其他违背社会公共利益或公共道德或依据相关农村综合产权交易服务平台协议、规则的规定不适合在农村综合产权交易服务平台上发布的。</u></strong></p>
                    <p><strong>【授权使用】</strong>对于您提供及发布除个人信息外的文字、图片、视频、音频等非个人信息，<strong>
                        <u>在版权保护期内您免费授予农村综合产权交易服务平台及其关联公司获得全球排他的许可使用权利及再授权给其他第三方使用的权利。您同意农村综合产权交易服务平台及其关联公司、支付宝公司存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。</u></strong>
                    </p>
                    <p>为方便您使用农村综合产权交易服务平台等其他相关服务，<strong>
                        <u>您授权农村综合产权交易服务平台将您在账户注册和使用农村综合产权交易服务平台服务过程中提供、形成的信息传递给农村综合产权交易服务平台等其他相关服务提供者，或从农村综合产权交易服务平台等其他相关服务提供者获取您在注册、使用相关服务期间提供、形成的信息。</u></strong>
                    </p>
                    <p></p>
                    <p class="margin-t-30 font-18 text-danger font-weight">六、用户的违约及处理</p>
                    <p><span class="font-warning"><strong>6.1</strong><strong>违约认定</strong></span></p>
                    <p>发生如下情形之一的，视为您违约：</p>
                    <p>（一）使用农村综合产权交易服务平台服务时违反有关法律法规规定的；</p>
                    <p>（二）违反本协议或本协议补充协议（即本协议第2.2条）约定的。</p>
                    <p>为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，<strong>
                        <u>农村综合产权交易服务平台可在农村综合产权交易服务平台规则中约定违约认定的程序和标准。如：农村综合产权交易服务平台可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。</u></strong>
                    </p>
                    <p><span class="font-warning"><strong>6.2</strong><strong>违约处理措施</strong></span></p>
                    <p><strong>【信息处理】</strong>您在农村综合产权交易服务平台上发布的信息构成违约的，<strong>
                        <u>农村综合产权交易服务平台可根据相应规则立即对相应信息进行删除、屏蔽处理或对您的商品进行下架、监管。</u></strong></p>
                    <p><strong>【行为限制】</strong>您在农村综合产权交易服务平台上实施的行为，或虽未在农村综合产权交易服务平台上实施但对农村综合产权交易服务平台及其用户产生影响的行为构成违约的，农村综合产权交易服务平台<strong>
                        <u>可依据相应规则对您执行账户扣分、限制参加营销活动、中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，农村综合产权交易服务平台可查封您的账户，终止向您提供服务。</u></strong>
                    </p>
                    <p><strong>【处理结果公示】
                        <u>农村综合产权交易服务平台可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在农村综合产权交易服务平台上予以公示。</u></strong></p>
                    <p><span class="font-warning"><strong>6.3</strong><strong>赔偿责任</strong></span></p>
                    <p><strong>
                        <u>如您的行为使农村综合产权交易服务平台及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿农村综合产权交易服务平台及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或其关联公司的上述全部损失。</u></strong></p>
                    <p><strong>
                        <u>如您的行为使农村综合产权交易服务平台及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或其关联公司遭受第三人主张权利，农村综合产权交易服务平台及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。</u></strong></p>
                    <p><span class="font-warning"><strong>6.4</strong><strong>特别约定</strong></span></p>
                    <p>
                        <strong>【商业贿赂】</strong>如您向农村综合产权交易服务平台及/或其关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。<strong>
                        <u>发生上述情形的，农村综合产权交易服务平台可立即终止与您的所有合作并向您收取违约金及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或赔偿金</u></strong>，该等金额以农村综合产权交易服务平台因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。</p>
                    <p><strong>【关联处理】
                        <u>如您因严重违约导致农村综合产权交易服务平台终止本协议时，出于维护平台秩序及保护消费者权益的目的，农村综合产权交易服务平台及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或其关联公司可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议第八条约定的方式通知您。</u></strong></p>
                    <p><strong>
                        <u>如农村综合产权交易服务平台与您签署的其他协议及农村综合产权交易服务平台及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或其关联公司与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则农村综合产权交易服务平台出于维护平台秩序及保护消费者权益的目的，可在收到指令时中止甚至终止协议，并以本协议第八条约定的方式通知您。</u></strong>
                    </p>
                    <p></p>
                    <p class="margin-t-30 font-18 text-danger font-weight">七、协议的变更</p>
                    <p>农村综合产权交易服务平台可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议第八条约定的方式通知您。</p>
                    <p>如您不同意变更事项，您有权于变更事项确定的生效日前联系农村综合产权交易服务平台反馈意见。如反馈意见得以采纳，农村综合产权交易服务平台将酌情调整变更事项。</p>
                    <p><strong>
                        <u>如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用农村综合产权交易服务平台服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用农村综合产权交易服务平台服务，则视为您同意已生效的变更事项。</u></strong>
                    </p>
                    <p></p>
                    <p class="margin-t-30 font-18 text-danger font-weight">八、通知</p>
                    <p>您同意农村综合产权交易服务平台以以下合理的方式向您送达各类通知：</p>
                    <p>（一）公示的文案；</p>
                    <p>（二）站内信、商务通弹出消息、客户端推送的消息；</p>
                    <p>（三）根据您预留于农村综合产权交易服务平台的联系方式发出的电子邮件、短信、函件等。</p>
                    <p></p>
                    <p class="margin-t-30 font-18 text-danger font-weight">九、协议的终止</p>
                    <p><span class="font-warning"><strong>9.1</strong><strong>终止的情形</strong></span></p>
                    <p><strong>【用户发起的终止】</strong>您有权通过以下任一方式终止本协议：</p>
                    <p>（一）在满足农村综合产权交易服务平台公示的<a href="https://service.taobao.com/support/knowledge-5839600.htm?spm=1.231242.247651.4&amp;dkey=rightRec" target="_blank">账户注销条件</a>时您通过<a href="https://service.taobao.com/support/main/selfHelpTools/cancelAccount/cancelAnnounce.htm?spm=1.231242.247651.5" target="_blank">网站自助服务</a>注销您的账户的；</p>
                    <p>（二）变更事项生效前您停止使用并明示不愿接受变更事项的；</p>
                    <p>（三）您明示不愿继续使用农村综合产权交易服务平台服务，且符合农村综合产权交易服务平台终止条件的。</p>
                    <p><strong>【农村综合产权交易服务平台发起的终止】</strong>出现以下情况时，农村综合产权交易服务平台可以本协议第八条的所列的方式通知您终止本协议：</p>
                    <p>（一）您违反本协议约定，农村综合产权交易服务平台依据违约条款终止本协议的；</p>
                    <p>（二）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，农村综合产权交易服务平台依据农村综合产权交易服务平台规则对您的账户予以查封的；</p>
                    <p>（三）除上述情形外，因您多次违反农村综合产权交易服务平台规则相关规定且情节严重，农村综合产权交易服务平台依据农村综合产权交易服务平台规则对您的账户予以查封的；</p>
                    <p>（四）您的账户被农村综合产权交易服务平台依据本协议回收的；</p>
                    <p>（五）您在农村综合产权交易服务平台有欺诈、发布或销售假冒伪劣/侵权商品、侵犯他人合法权益或其他严重违法违约行为的；</p>
                    <p>（六）其它应当终止服务的情况。</p>
                    <p><span class="font-warning"><strong>9.2</strong><strong>协议终止后的处理</strong></span></p>
                    <p><strong>【用户信息披露】
                        <u>本协议终止后，除法律有明确规定外，农村综合产权交易服务平台无义务向您或您指定的第三方披露您账户中的任何信息。</u></strong></p>
                    <p><strong>【农村综合产权交易服务平台权利】</strong>本协议终止后，农村综合产权交易服务平台仍享有下列权利：</p>
                    <p>（一）继续保存您留存于农村综合产权交易服务平台的本协议第五条所列的各类信息；</p>
                    <p>（二）对于您过往的违约行为，农村综合产权交易服务平台仍可依据本协议向您追究违约责任。</p>
                    <p><strong>【交易处理】
                        <u>本协议终止后，对于您在本协议存续期间产生的交易订单，农村综合产权交易服务平台可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</u></strong>
                    </p>
                    <p></p>
                    <p class="margin-t-30 font-18 text-danger font-weight">十、法律适用、管辖与其他</p>
                    <p><strong>【法律适用】
                        <u>本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及</u></strong><strong>
                        <u>/</u></strong><strong>
                        <u>或行业惯例。</u></strong></p>
                    <p><strong>【管辖】
                        <u>您因使用农村综合产权交易服务平台服务所产生及与农村综合产权交易服务平台服务有关的争议，由农村综合产权交易服务平台与您协商解决。协商不成时，任何一方均可向土流集团有限公司住所地人民法院提起诉讼。</u></strong></p>
                    <p><strong>【可分性】</strong>本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
                    <p class="text-danger font-14">本协议自2014年1月1日生效，农村综合产权交易服务平台保留最终解释权。</p>
                </div>
			<template v-slot:modal-footer="{ ok }">
				<b-button variant="success" class="width-200" @click="ok()">
					我知道了
				</b-button>
			</template>
		</b-modal>
		<b-modal title="法律声明及隐私保护政策" dialog-class="width-1200" v-model="isLawShow"  footer-class="justify-content-center" hide-header-close  ok-title="我知道了" ok-variant="success" modal-cancel="true">
			<div class="text-gray-5 line-h-28 f-14">
                    <p>
						<span class="text-danger">
						农村综合产权交易服务平台提醒您：在使用农村综合产权交易服务平台各项服务前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用农村综合产权交易服务平台服务，但如果您使用农村综合产权交易服务平台服务的，您的使用行为将被视为对本声明全部内容的认可。</span>“农村综合产权交易服务平台”指由土流集团有限公司（简称“农村综合产权交易服务平台”）运营的网络交易平台，域名为tuliu.com以及农村综合产权交易服务平台启用的其他域名。
					</p>
                    <p>
						鉴于农村综合产权交易服务平台提供的服务属于电子公告牌（BBS）服务，农村综合产权交易服务平台上关于农村综合产权交易服务平台会员或其发布的相关商品（包括但不限于店铺名称、公司名称、联系人及联络信息，产品的描述和说明，相关图片、视讯等）的信息均由会员自行提供，不得发布或提供任何法律法规禁止公开的信息或资料，会员依法应对其提供的任何信息承担全部责任，包括因此给农村综合产权交易服务平台造成的全部损失。</p>
                    <p>
                        任何单位或个人认为农村综合产权交易服务平台网页内容（包括但不限于农村综合产权交易服务平台会员发布的商品信息）可能涉嫌侵犯其合法权益，应该及时向农村综合产权交易服务平台提出书面权利通知，并提供身份证明、权属证明、具体链接（URL）及详细侵权情况证明。农村综合产权交易服务平台在收到上述法律文件后，将会依法尽快移除相关涉嫌侵权的内容。</p>
                    <p>
                        农村综合产权交易服务平台转载作品（包括论坛内容）出于传递更多信息之目的，并不意味农村综合产权交易服务平台（包括农村综合产权交易服务平台关联企业）赞同其观点或证实其内容的真实性。农村综合产权交易服务平台尊重合法版权，反对侵权盗版。若本网有部分文字、摄影作品等侵害了您的权益，请通过农村综合产权交易服务平台客服反馈提交通知，我们会尽快处理。</p>
                    <p><span class="text-warning font-weight">知识产权声明</span></p>
                    <p> 农村综合产权交易服务平台拥有农村综合产权交易服务平台网站内所有信息内容（除农村综合产权交易服务平台会员发布的商品信息外，包括但不限于文字、图片、软件、音频、视频）的版权。</p>
                    <p> 任何被授权的浏览、复制、打印和传播属于农村综合产权交易服务平台网站内信息内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此版权声明；</p>
                    <p>
                        农村综合产权交易服务平台所有的产品、技术与所有程序均属于农村综合产权交易服务平台知识产权。“TULIU.COM”、“农村综合产权交易服务平台”以及农村综合产权交易服务平台其他产品服务名称及相关图形、标识等为农村综合产权交易服务平台的注册商标。未经农村综合产权交易服务平台许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，农村综合产权交易服务平台将依法追究法律责任。</p>
                    <p><span class="text-warning font-weight">隐私权政策</span></p>
                    <p>
                        农村综合产权交易服务平台尊重并保护所有使用农村综合产权交易服务平台服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，农村综合产权交易服务平台会按照本隐私权政策的规定使用和披露您的个人信息。但农村综合产权交易服务平台将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，农村综合产权交易服务平台不会将这些信息对外披露或向第三方提供。农村综合产权交易服务平台会不时更新本隐私权政策。
                        您在同意农村综合产权交易服务平台服务协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于农村综合产权交易服务平台服务协议不可分割的一部分。</p>
                    <p><strong> 1. 适用范围</strong></p>
                    <p>a） 在您注册或激活可以登录农村综合产权交易服务平台的账户时，您在农村综合产权交易服务平台或农村综合产权交易服务平台其他平台提供的个人注册信息（应法律法规要求需公示的企业名称等相关工商注册信息以及自然人经营者的信息除外）；</p>
                    <p>b）
                        在您使用农村综合产权交易服务平台服务，或访问农村综合产权交易服务平台网页时，农村综合产权交易服务平台自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；如您下载或使用农村综合产权交易服务平台或其关联公司移动客户端软件，或访问移动网页使用农村综合产权交易服务平台服务时，农村综合产权交易服务平台可能会读取与您位置和移动设备相关的信息，包括但不限于设备型号、设备识别码、操作系统、分辨率、电信运营商等。</p>
                    <p> c） 农村综合产权交易服务平台通过合法途径从商业伙伴处取得的用户个人数据。</p>
                    <p> 您了解并同意，以下信息不适用本隐私权政策：</p>
                    <p> a）您在使用农村综合产权交易服务平台提供的搜索服务时输入的关键字信息；</p>
                    <p> b）在您未选择“匿名购买”和/或“匿名评价”功能时，农村综合产权交易服务平台收集到的您在农村综合产权交易服务平台进行交易的有关数据，包括但不限于出价、成交信息及评价详情；</p>
                    <p> c）信用评价、违反法律规定或违反农村综合产权交易服务平台规则行为及农村综合产权交易服务平台已对您采取的措施。</p>
                    <p><strong> 2. 信息使用</strong></p>
                    <p>a)
                        农村综合产权交易服务平台不会向第三方(农村综合产权交易服务平台关联公司除外)提供、分享您的个人信息，除非事先得到您的许可，或该第三方和农村综合产权交易服务平台（含农村综合产权交易服务平台关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</p>
                    <p>b) 农村综合产权交易服务平台亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何农村综合产权交易服务平台用户如从事上述活动，一经发现，农村综合产权交易服务平台有权立即终止与该用户的服务协议。</p>
                    <p>c)
                        为服务用户的目的，农村综合产权交易服务平台或其关联公司可能通过使用您的个人信息，向您提供您可能感兴趣的信息，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或者与农村综合产权交易服务平台合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。</p>
                    <p>d) 农村综合产权交易服务平台可以使用您的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与农村综合产权交易服务平台或其关联公司协议、政策或规则的行为，以保护您、其他农村综合产权交易服务平台用户，或农村综合产权交易服务平台或其关联公司合法权益。</p>
                    <p><strong> 3. 信息披露</strong></p>
                    <p> 在如下情况下，农村综合产权交易服务平台将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</p>
                    <p> a) 经您事先同意，向第三方披露；</p>
                    <p> b) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</p>
                    <p> c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
                    <p> d) 如您出现违反中国有关法律、法规或者农村综合产权交易服务平台服务协议或相关规则的情况，需要向第三方披露；</p>
                    <p> e) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
                    <p>f) 在农村综合产权交易服务平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，农村综合产权交易服务平台有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。</p>
                    <p> g) 其它农村综合产权交易服务平台根据法律、法规或者网站政策认为合适的披露。</p>
                    <p><strong> 4. 信息存储和交换</strong></p>
                    <p>农村综合产权交易服务平台收集的有关您的信息和资料将保存在农村综合产权交易服务平台及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或农村综合产权交易服务平台收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>
                    <p><strong> 5. Cookie的使用</strong></p>
                    <p>a)
                        在您未拒绝接受cookies的情况下，农村综合产权交易服务平台会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的农村综合产权交易服务平台服务或功能。农村综合产权交易服务平台使用cookies可为您提供更加周到的个性化服务，包括推广服务。</p>
                    <p>b)
                        您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的农村综合产权交易服务平台服务或功能。</p>
                    <p> c) 通过农村综合产权交易服务平台所设cookies所取得的有关信息，将适用本政策；</p>
                    <p><strong> 6. 信息安全</strong></p>
                    <p>a)
                        您的账户均有安全保护功能，请妥善保管您的账户及密码信息。农村综合产权交易服务平台将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。</p>
                    <p>b)
                        在使用农村综合产权交易服务平台服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是你的账户及密码发生泄露，请您立即联络农村综合产权交易服务平台客服，以便农村综合产权交易服务平台采取相应措施。</p>
                    <p><strong> 7. 未成年人的特别注意事项</strong></p>
                    <p> 如果您不是具备完全民事权利能力和完全民事行为能力的自然人，您无权使用农村综合产权交易服务平台服务，因此农村综合产权交易服务平台希望您不要向我们提供任何个人信息。</p>
                </div>
			<template v-slot:modal-footer="{ ok }">
				<b-button variant="success" class="width-200" @click="ok()">
					我知道了
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
	import icpFooter from '@/components/footer/icp-footer';
	import simpleHeader from '@/components/header/simple-header';
	export default {
		name: 'register',
		components: {
			icpFooter,
			simpleHeader
		},
		data() {
			return {
				isServiceShow:false,
				isLawShow:false,
				tips: '',
				timer: null,
				current: 0,
				companyName: '',
				legalName: '',
				password: '',
				mobile: '',
				code: '',
				seconds: 0,
				isAgree:false,
				isEyeOpen: false,
			}
		},
		methods: {
			getCode() {
				if(this.verifyMobile()) {
					this.seconds = 60;
					this.timer = setInterval(() => {
						// 执行加或减函数
						if (this.seconds > 0) {
							this.seconds--;
						} else {
							clearInterval(this.timer);
						}
					}, 1000);
				}
			},
			onEyeToggle() {
				this.isEyeOpen = this.isEyeOpen ? false : true;
			},
			verifyMobile() {
				if (this.mobile == '') {
					this.tips = '请输入手机号码'
					return false
				} else {
					if (!this.$test.mobile(this.mobile)) {
						this.tips = '请输入正确的手机号码'
						return false
					}
				}
				return true;
			},
			verify() {
				if (this.current == 1) {
					if (this.companyName == '') {
						this.tips = '请输入企业名称'
						return false
					}
					if (this.legalName == '') {
						this.tips = '请输入法人姓名'
						return false
					} else {
						if (!this.$test.trueName(this.legalName)) {
							this.tips = '法人姓名格式错误'
							return false
						}
						if (!this.$test.firstName(this.legalName.substr(0, 1))) {
							this.tips = '法人姓名姓氏有误'
							return false
						}
					}
				} 
				if(!this.verifyMobile()) {
					return false
				} 
				if(this.code == '') {
					this.tips = '请输入动态码'
					return false
				}
				if (this.password == '') {
					this.tips = '请输入密码'
					return false
				}
				if (this.isAgree == 'false') {
					this.tips = '请阅读并同意服务协议及隐私保护政策'
					return false
				}
				return true;
			},
			onSwitch(i) {
				if (this.current != i) {
					this.tips = '';
					if (i == 0) {
						this.mobile = '';
						this.code = '';
					} else {
						this.username = '';
						this.password = '';
					}
				}
				this.current = i;
			},
			register() {
				if(this.verify()) {
					this.$http.post(this.$api.user.register,{
						companyName: this.companyName,
						legalName: this.legalName,
						mobile: this.mobile,
						password: this.password,
						verifyCode: this.code,
						nicName: "游客"
					}).then(res => {
						if (res.code == 1) {
							this.$store.commit('user/SET_TOKEN', res.result.token);
							let user = {
								uid: res.result.id,
								headPic: res.result.headPic,
								nickName: res.result.nicName,
								mobile: res.result.mobile,
								vip: false,
							}
							this.$store.commit('user/SET_MEMBER', user);
							this.$dialog.alert({
								title: "温馨提示",
								message: "您的个人信息尚未填写,先完善信息?",
								showCancelButton: true,
								cancelButtonText: '先逛逛',
								confirmButtonText: '完善信息'
							}).then(() => {
								this.$router.replace({
									path: '/user/account'
								})
							}).catch(() => {
								this.$router.replace({
									path: '/'
								})
							})
						} else {
							this.tips = res.reason;
						}
					})
				}
				
			}
		}
	}
</script>


<style scoped>
	.page-bg {
		background-image: url('~@/assets/img/lr-bg.jpg');
		height: 550px;
		width: 100%;
		position: relative;
		background-position: center, center;
		background-size: cover;
	}

	.header {
		width: 700px;
	}

	.custom-control-label,
	strong {
		font-size: 12px;
		line-height: 25px;
	}

	span {
		font-size: 14px;
	}

	.account-header {
		width: 100%;
		height: 115px;
		background-color: #fff
	}


	.grid .warp {
		float: right;
		margin-right: 40px;
		position: relative;
		overflow: hidden
	}

	input[type=text],
	input[type=password] {
		border: 1px solid #e5e5e5;
		display: inline-block;
		background-color: #fff
	}

	label.icon {
		float: left;
		display: block;
		text-align: center;
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
		background-repeat: no-repeat;
		background-color: #ddd;
		background-position: center
	}

	label.icon-phone {
		background-image: url('~@/assets/icon/iphone.png')
	}

	label.icon-captcha {
		background-image: url('~@/assets/icon/dynamiccode.png')
	}

	label.icon-password {
		background-image: url('~@/assets/icon/password.png')
	}

	.signup {
		width: 332px;
		border: 1px solid #e5e5e5;
		background-color: #fff;
		position: relative;
		opacity: .95;
		filter: alpha(opacity=95)
	}

	.signup-header {
		height: 60px;
		line-height: 60px;
		border-bottom: 2px solid #00CC00;
		margin: 0 25px;
		color: #333
	}

	.signup-header_title {
		text-align: center
	}

	.signup-details {
		overflow: hidden
	}

	.signup-details_form__item {
		margin: 10px 24px 10px;
		position: relative;
		display:flex;
		justify-content: center;
		align-items: center;
	}

	.signup-details_form__item label.icon {
		width: 36px;
		height: 36px;
		line-height: 36px
	}

	.signup-details_form__item label.radio {
		cursor: pointer
	}

	.signup-details_form__item label.radio:first-of-type {
		margin-right: 45px
	}

	.signup-details_form__item label.protocal {
		float: left;
		width: 260px;
		margin-top: 4px;
		cursor: pointer
	}

	.signup-details_form__item label.protocal a {
		height: 33px;
		color: #000;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: .3px;
		text-indent: -2px;
		text-decoration: underline;
		text-underline-position: under
	}

	.signup-details_form__item input[type=text],
	.signup-details_form__item input[type=password] {
		width: 246px;
		padding-left: 4px;
		font-size: 14px;
		height: 36px;
		outline: 0
	}

	.signup-details_form__item #signup-btn {
		height: 40px;
		font-size: 16px
	}

	.signup-details_form__item.signin {
		font-size: 12px;
		color: #6c6c6c;
		line-height: 12px;
		text-align: center
	}

	label{
		margin-bottom: 0px;
	}

	.eye-icon {
		position: absolute;
		width: 32px;
		height: 32px;
		right: 3px;
		top: 2px;
		display: table-cell;
		cursor: pointer;
		text-align: center;
		background-color: #fff
	}

	.eye-open {
		background: url('~@/assets/icon/eye-open.png') no-repeat center
	}

	.eye-close {
		background: url('~@/assets/icon/eye-close.png') no-repeat center
	}


	.authorize-login.register {
		padding-bottom: 0;
		margin-top: -8px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: justify;
		justify-content: space-between
	}

	.authorize-login-item {
		float: left;
		margin-right: 8px;
		font-size: 0
	}

	.qq-login {
		background: url('~@/assets/icon/qq.png') no-repeat;
		width: 25px;
		height: 25px;
		display: inline-block
	}

	.sina-login {
		background: url('~@/assets/icon/weibo.png') no-repeat;
		width: 25px;
		height: 25px;
		display: inline-block
	}

	.wechat-login {
		background: url('~@/assets/icon/wechat.png') no-repeat;
		width: 25px;
		height: 25px;
		display: inline-block;
		cursor: pointer
	}

	.login-other-ways-login {
		padding: 20px 26px 10px;
		margin: 0;
		border-top: dashed 1px #ebebeb;
		background-color: #f3f3f3;
		filter: alpha(opacity=95);
		opacity: .95;
		position: relative
	}



	@media screen and (max-width:1200px) {
		.grid {
			width: 100%;
			margin: 0 auto
		}
	}

	@media screen and (max-width:360px) {
		.grid {
			width: 360px;
			margin: 0 auto
		}
	}
</style>
